.wrapper {

	& :global(.wp-block-embed__wrapper) {
		align-items: center;
		display: flex;
		flex-direction: column;
	}

	& :global(.wp-element-caption) {
		margin-left: auto;
		margin-right: auto;
		width: fit-content;
	}
}
