.hintText {
	--color: var(--form-input-color-helper);
	--color-error: var(--form-input-color-error);
	--font-family: var(--form-input-typography-helper-family);
	--font-size: var(--form-input-typography-helper-size);
	--font-weight: var(--form-input-typography-helper-weight);
	--letter-spacing: var(--form-input-typography-helper-letter-spacing);
	--line-height: var(--form-input-typography-helper-line-height);

	color: var(--color);
	display: block;
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	margin-top: var(--spacing-2xs);
	padding-inline: var(--spacing-2xs);
}

.hintHasError {
	color: var(--color-error);
}

@container style(--is-dark-mode: 1) {

	.hintText {
		--color: var(--form-input-color-dark-helper);
		--color-error: var(--form-input-color-dark-error);
	}
}
