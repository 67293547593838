.inputLabel {
	--color: var(--form-input-color-label);
	--font-family: var(--form-input-typography-label-family);
	--font-size: var(--form-input-typography-label-size);
	--font-weight: var(--form-input-typography-label-weight);
	--letter-spacing: var(--form-input-typography-label-letter-spacing);
	--line-height: var(--form-input-typography-label-line-height);

	color: var(--color);
	display: block;
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	margin-bottom: var(--spacing-xs);
}

.inputLabelHidden {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	margin-bottom: 0;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	word-wrap: normal !important;
}

@container style(--is-dark-mode: 1) {

	.inputLabel {
		--color: var(--form-input-color-dark-label);
	}
}
