.carouselHero {
	--aspect-ratio-landscape: 1440 / 1025;
	--aspect-ratio-portrait: 430 / 932;
	--outline-color: var(--color-theme-dark-link-focus-outline-color);
	--outline-style: var(--color-theme-dark-link-focus-outline-style);
	--outline-width: var(--color-theme-dark-link-focus-outline-width);

	background-color: var(--carousel-color-background);
	margin-left: var(--margin-full-width);
	margin-right: var(--margin-full-width);
	max-width: var(--screen-width);

	& :global(.carousel) {
		overflow: hidden;
	}
}

.carouselHeroWrapperContainer {
	margin-left: 0;
}

.carouselHeroWrapper {
	margin-inline: auto;
	position: relative;
}

.carouselHeroSlide {
	--reserved-space: calc(var(--scroll-padding, 80px) - var(--header-ad-height, 0px));
	--slide-spacing: 0;
	--slide-size: 100%;

	@media (--bp-medium) {
		--slide-spacing: 0;
		--slide-size: 100%;
	}

	@media (--bp-large) {
		--slide-spacing: 0;
		--slide-size: 100%;
	}

	align-items: center;
	background-color: var(--carousel-color-background);
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	min-height: calc(100vh - var(--reserved-space));
	position: relative;
	width: 100%;

	@supports (height: 100svh) {
		min-height: calc(100svh - var(--reserved-space));
	}

	& a {
		pointer-events: all;
	}
}

.carouselHeroImageWrapper {
	height: 100%;
	left: 0;
	margin: 0;
	object-fit: cover;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 100%;

	&::after {
		background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 75%) 100%);
		bottom: 0;
		content: "";
		display: block;
		height: 66%;
		position: absolute;
		width: 100%;
	}
}

.carouselHeroImage {
	display: block;
	height: 100%;
	object-fit: cover;
	transition: transform 5s var(--transition-easing-in-out);
	width: 100%;
}

.carouselHeroSlideActive .carouselHeroImage {
	transform: scale(1.1);
}

.carouselHeroContent {
	color: var(--color-neutral-white);
	margin: 0;
	padding: calc(var(--gutter) * 2) var(--gutter) 8rem var(--gutter);
	text-align: center;
	z-index: 1;
}

.carouselHeroSlideTitle {
	color: var(--color-neutral-white);
	font-size: 2.5rem;
	line-height: 1;
	margin: 0 auto 0.5rem;
	max-width: var(--max-width-wide);
}

.carouselHeroSlideLink {
	--color: var(--color-neutral-white);
	--color-text-decoration: var(--color-neutral-white);

	font-size: 2.5rem;
	line-height: 1;

	&::after {
		content: "";
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&:hover,
	&:focus,
	&:active {
		--color: var(--color-neutral-white);
		--color-text-decoration: var(--color-neutral-white);
	}
}

.carouselHeroCta {
	bottom: 2.5rem;
	display: flex;
	justify-content: center;
	left: 0;
	position: absolute;
	width: 100%;
	z-index: 1;
}

.carouselHeroCtaButton {
	align-items: center;
	background-color: transparent;
	border: 1px solid var(--color-neutral-light-20);
	border-radius: var(--radius-xs);
	color: var(--color-neutral-white);
	display: flex;
	font-family: var(--typography-btn-family);
	font-size: 0.9375rem;
	font-weight: 700;
	gap: 0.5rem;
	line-height: 1;
	padding: var(--spacing-2xs) var(--spacing-m);
	text-align: center;
	text-decoration: none;
	transition: background-color 0.3s var(--transition-easing-in-out), color 0.3s var(--transition-easing-in-out);

	&:hover {
		background-color: var(--color-neutral-white);
		color: var(--color-neutral-black);
	}

	&:focus {
		background-color: inherit;
		border-color: var(--color-neutral-white);
		color: var(--color-neutral-white);
	}
}

.carouselHeroControls {
	bottom: 6.5rem;
}
