.wrapper {
	--margin-block: var(--newsletter-vertical-spacing);
	--padding: var(--spacing-s);
	--color: var(--newsletter-color-text);
	--btn-width: 120px;
	--max-container-width: 1128px;

	@media (--bp-medium) {
		--margin-block: var(--newsletter-vertical-desktop-spacing);
		--padding: var(--spacing-s-desktop);
	}
	margin-block: var(--margin-block);
	margin-inline: auto;
}

.container,
.thanksContainer,
.emailSentWrapper {
	--padding: var(--spacing-s);
	--max-container-width: 1128px;
	--color: var(--newsletter-color-text);

	border-radius: var(--radius-containers);
	color: var(--color);
	margin-inline: auto;
	max-width: var(--max-container-width);
	padding: var(--padding);
}

.thanksContainer,
.emailSentWrapper {
	--padding: var(--spacing-m);
	display: flex;
	flex: 1;
	justify-content: center;
}

.thanksInnerContainer {
	text-align: center;
	width: 667px;
}

.socialButtons {
	display: grid;
	gap: var(--spacing-3xs);
	grid-template-columns: repeat(2, 1fr);
	margin-top: var(--spacing-3xs);;
}

.formContainer {
	align-items: center;
	align-self: stretch;
	display: flex;
	flex-direction: column;
	gap: var(--spacing-3xs);
	margin-top: var(--spacing-3xs);
}

.emailInput {
	flex: 1;
}

.emailInput,
.submitButton,
.subscribeButton {
	width: 100%;
}

.subscribeButton {
	margin-bottom: var(--spacing-xs);
}

.form {
	container-name: newsletter-form;
}

.changeButton {
	border-radius: var(--radius-buttons);
	gap: var(--spacing-4xs);
	justify-content: center;
	margin-right: var(--spacing-3xs);
	width: var(--btn-width);
}

.toggleButton {
	border-radius: var(--radius-buttons);
	justify-content: center;
	width: var(--btn-width);
}

.title {
	--font-family: var(--newsletter-typography-title-family);
	--font-size: var(--newsletter-typography-title-size);
	--font-weight: var(--newsletter-typography-title-weight);
	--letter-spacing: var(--newsletter-typography-title-letter-spacing);
	--line-height: var(--newsletter-typography-title-line-height);

	@media (--bp-large) {
		--font-family: var(--newsletter-typography-title-desktop-family);
		--font-size: var(--newsletter-typography-title-desktop-size);
		--font-weight: var(--newsletter-typography-title-desktop-weight);
		--letter-spacing: var(--newsletter-typography-title-desktop-letter-spacing);
		--line-height: var(--newsletter-typography-title-desktop-line-height);
	}

	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
}

.subhead {
	--font-family: var(--newsletter-typography-subhead-family);
	--font-size: var(--newsletter-typography-subhead-size);
	--font-weight: var(--newsletter-typography-subhead-weight);
	--letter-spacing: var(--newsletter-typography-subhead-letter-spacing);
	--line-height: var(--newsletter-typography-subhead-line-height);

	@media (--bp-large) {
		--font-family: var(--newsletter-typography-subhead-desktop-family);
		--font-size: var(--newsletter-typography-subhead-desktop-size);
		--font-weight: var(--newsletter-typography-subhead-desktop-weight);
		--letter-spacing: var(--newsletter-typography-subhead-desktop-letter-spacing);
		--line-height: var(--newsletter-typography-subhead-desktop-line-height);
	}

	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	margin-top: var(--spacing-xs);
}

.disclaimerWrapper {
	display: flex;
	margin-top: var(--spacing-xs);
}

.disclaimer {
	--color: var(--newsletter-color-disclaimer);
	--font-family: var(--newsletter-typography-disclaimer-family);
	--font-size: var(--newsletter-typography-disclaimer-size);
	--font-weight: var(--newsletter-typography-disclaimer-weight);
	--letter-spacing: var(--newsletter-typography-disclaimer-letter-spacing);
	--line-height: var(--newsletter-typography-disclaimer-line-height);

	@media (--bp-large) {
		--font-family: var(--newsletter-typography-disclaimer-desktop-family);
		--font-size: var(--newsletter-typography-disclaimer-desktop-size);
		--font-weight: var(--newsletter-typography-disclaimer-desktop-weight);
		--letter-spacing: var(--newsletter-typography-disclaimer-desktop-letter-spacing);
		--line-height: var(--newsletter-typography-disclaimer-desktop-line-height);
	}

	color: var(--color);
	flex: 1;
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);

	& a {
		text-decoration: underline;
	}
}

.loggedInText {
	--font-weight: var(--typography-caption-weight);
	--font-size: var(--typography-caption-size);
	--line-height: var(--typography-caption-line-height);
	--letter-spacing: var(--typography-caption-letter-spacing);
	--font-family: var(--typography-caption-family);

	@media (--bp-medium) {
		--font-weight: var(--typography-caption-desktop-weight);
		--font-size: var(--typography-caption-desktop-size);
		--line-height: var(--typography-caption-desktop-line-height);
		--letter-spacing: var(--typography-caption-desktop-letter-spacing);
		--font-family: var(--typography-caption-desktop-family);
	}

	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
}

.emailSentWrapper {
	margin-inline: auto;
}

.emailSentHeader {
	--font-weight: var(--typography-h-l-family);
	--font-size: var(--typography-h-l-size);
	--line-height: var(--typography-h-l-line-height);
	--letter-spacing: var(--typography-h-l-letter-spacing);
	--font-family: var(--typography-h-l-family);

	@media (--bp-medium) {
		--font-weight: var(--typography-h-l-desktop-weight);
		--font-size: var(--typography-h-l-desktop-size);
		--line-height: var(--typography-h-l-desktop-line-height);
		--letter-spacing: var(--typography-h-l-desktop-letter-spacing);
		--font-family: var(--typography-h-l-desktop-family);
	}

	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
}

.checkTerms {
	margin-right: var(--spacing-4xs);
	padding: var(--spacing-4xs);

	& input:hover {
		cursor: pointer;
	}
}

.hasError {

	& input {
		-webkit-appearance: none;
		appearance: none;
		border: 1px solid var(--form-input-color-error);
		border-radius: var(--form-input-border-radius);
		height: 1rem;
		width: 1rem;
	}
}

.errorMessage {
	color: var(--form-input-color-error);
	margin-top: var(--spacing-4xs);
}

.newslettersList {
	display: grid;
	gap: var(--spacing-3xs);
	grid-template-columns: repeat(2, 1fr);
	margin-bottom: var(--spacing-xs);
	margin-top: var(--spacing-xs);
}

@container (min-width: 768px) {

	.container {
		align-items: center;
		display: flex;
		flex-direction: row;
		gap: var(--grid-l-gutter);
		justify-content: space-between;
	}

	.content,
	.form {
		flex: 1;
		max-width: calc(var(--max-container-width) / 2);
	}

	.container .formContainer {
		flex-direction: row;
	}

	.submitButton {
		width: var(--btn-width);
	}
}

@container newsletter-form (min-width: 384px) {

	.isInContent {

		& .formContainer {
			flex-direction: row;
		}

		& .submitButton {
			width: var(--btn-width);
		}
	}
}

.spinner {
	--margin-block: var(--newsletter-vertical-spacing);
	align-items: center;
	display: flex;
	font-size: 5rem;
	justify-content: center;
	margin-bottom: var(--margin-block);
	margin-top: var(--margin-block);

	& svg {
		height: 1em;
		width: 1em;
	}
}

.emailSentSubhead,
.resendEmail {
	--font-weight: var(--typography-body-s-family);
	--font-size: var(--typography-body-s-size);
	--line-height: var(--typography-body-s-line-height);
	--letter-spacing: var(--typography-body-s-letter-spacing);
	--font-family: var(--typography-body-s-family);

	@media (--bp-medium) {
		--font-weight: var(--typography-body-s-desktop-weight);
		--font-size: var(--typography-body-s-desktop-size);
		--line-height: var(--typography-body-s-desktop-line-height);
		--letter-spacing: var(--typography-body-s-desktop-letter-spacing);
		--font-family: var(--typography-body-s-desktop-family);
	}

	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
}

.emailSentSubhead {
	margin-bottom: var(--spacing-s);
	margin-top: var(--spacing-s);
}

.logInSeparator {
	align-items: center;
	display: flex;
	font-size: var(--typography-caption-size);
	gap: var(--spacing-2xs);
	line-height: 1;

	margin-bottom: var(--spacing-2xs);
	margin-top: var(--spacing-2xs);

	& span {
		white-space: nowrap;
	}

	&::before,
	&::after {
		background-color: var(--separator-color);
		content: "";
		display: block;
		flex-grow: 1;
		height: 1px;
		width: 100%;
	}
}
