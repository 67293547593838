.checkbox {
	--background-color: var(--form-input-color-background-default);
	--border-radius: var(--form-input-border-radius);
	--border-color: var(--form-input-color-border-default);
	--border-style: var(--form-input-border-style);
	--border-color-error: var(--form-input-color-error);
	--border-color-hover: var(--form-input-color-border-hover);
	--border-width: var(--form-input-border-width);
	--color: var(--form-input-color-default);
	--font-family: var(--form-input-typography-default-family);
	--font-size: var(--form-input-typography-default-size);
	--font-weight: var(--form-input-typography-default-weight);
	--horizontal-spacing: var(--spacing-2xs);
	--letter-spacing: var(--form-input-typography-default-letter-spacing);
	--line-height: var(--form-input-typography-default-line-height);
	--outline-focus: var(--form-input-color-border-focus);
}

@container style(--is-dark-mode: 1) {

	.checkbox {
		--background-color: var(--form-input-color-dark-background-default);
		--border-color: var(--form-input-color-dark-border-default);
		--border-color-error: var(--form-input-color-dark-error);
		--border-color-hover: var(--form-input-color-dark-border-hover);
		--color: var(--form-input-color-dark-default);
		--outline-focus: var(--form-input-color-dark-border-focus);

		& .checkboxInput {

			&:focus-visible ~ .checkboxControl {
				--background-color: var(--form-input-color-dark-background-focus);
			}

			&:checked:not(:disabled) ~ .checkboxControl,
			&:indeterminate ~ .checkboxControl {
				--background-color: var(--color-neutral-white);
				--border-color: var(--form-input-color-dark-border-active);
				--color: var(--color-neutral-black);
			}

			&:disabled ~ .checkboxControl {
				--background-color: var(--color-neutral-light-10);
				--border-color: var(--color-neutral-light-20);
				--color: var(--color-neutral-light-40);
			}

			&:indeterminate ~ .checkboxControl {
				position: relative;

				&::after {
					background-color: var(--form-input-color-default);
				}
			}
		}

		& .checkboxLabel {
			--color: var(--form-input-color-dark-label);
		}
	}
}

.checkboxWrapper {
	align-items: center;
	display: flex;
	gap: var(--horizontal-spacing);
	position: relative;

	&:hover:not(:disabled) .checkboxControl {
		border-color: var(--border-color-hover);
	}
}

.checkboxInput {
	cursor: pointer;
	height: 0;
	opacity: 0;
	position: absolute;
	width: 0;

	&:focus-visible ~ .checkboxControl {
		--background-color: var(--form-input-color-background-focus);

		outline: var(--border-width) solid var(--outline-focus);
		outline-offset: 0.125rem;
	}

	&:disabled ~ .checkboxControl {
		--background-color: rgb(from #1d1d1d r g b / 33%);
		--border-color: var(--color-neutral-dark-20);

		cursor: not-allowed;
	}

	&:checked:not(:disabled) ~ .checkboxControl,
	&:indeterminate ~ .checkboxControl {
		--background-color: var(--color-neutral-black);
		--color: var(--color-neutral-white);
	}

	&:checked ~ .checkboxControl .checkboxCheckmark {
		display: block;
	}

	&:indeterminate ~ .checkboxControl {
		position: relative;

		&::after {
			background-color: var(--color);
			content: "";
			height: 0.0625rem;
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 0.5rem;
		}
	}
}

.checkboxControl {
	align-items: center;
	background-color: var(--background-color);
	border-color: var(--border-color);
	border-radius: var(--border-radius);
	border-style: var(--border-style);
	border-width: var(--border-width);
	box-sizing: border-box;
	color: var(--color);
	display: flex;
	flex-shrink: 0;
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	height: 1rem;
	justify-content: center;
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	order: 0;
	transition: border-color var(--transition-speed-fast) ease-in-out, outline-offset var(--transition-speed-fast) ease-in-out;
	width: 1rem;
}

.checkboxCheckmark {
	display: none;
	height: 100%;
	padding: 0.125rem;
	width: 100%;
}

.checkboxLabel {
	--color: var(--form-input-color-label);
	--font-family: var(--form-input-typography-label-family);
	--font-size: var(--form-input-typography-label-size);
	--font-weight: var(--form-input-typography-label-weight);
	--letter-spacing: var(--form-input-typography-label-letter-spacing);
	--line-height: var(--form-input-typography-label-line-height);

	color: var(--color);
	display: block;
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
}
