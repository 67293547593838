.radio {
	--background-color: transparent;
	--border-radius: 50%;
	--border-color: var(--form-input-color-border-default);
	--border-style: var(--form-input-border-style);
	--border-color-error: var(--form-input-color-error);
	--border-color-hover: var(--form-input-color-border-hover);
	--border-width: var(--form-input-border-width);
	--color: var(--form-input-color-default);
	--font-family: var(--form-input-typography-default-family);
	--font-size: var(--form-input-typography-default-size);
	--font-weight: var(--form-input-typography-default-weight);
	--horizontal-spacing: var(--spacing-2xs);
	--letter-spacing: var(--form-input-typography-default-letter-spacing);
	--line-height: var(--form-input-typography-default-line-height);
	--outline-focus: var(--form-input-color-border-focus);
}

:global(.has-dark-theme) .radio,
:global(.has-dark-theme).radio {
	--border-color: var(--form-input-color-dark-border-default);
	--border-color-error: var(--form-input-color-dark-error);
	--border-color-hover: var(--form-input-color-dark-border-hover);
	--color: var(--form-input-color-dark-default);
	--outline-focus: var(--form-input-color-dark-border-focus);

	& .radioInput {

		&:focus-visible ~ .radioControl {
			--background-color: var(--form-input-color-dark-background-focus);
		}

		&:checked {
			--border-color: var(--form-input-color-dark-border-active);

			& ~ .radioControl {
				--border-color: var(--form-input-color-dark-border-active);

				&::after {
					--background-color: var(--form-input-color-dark-border-active);
				}
			}
		}

		&:disabled ~ .radioControl {
			--background-color: rgb(from #fff r g b / 33%);
			--border-color: rgb(from #fff r g b / 66%);
		}
	}

	& .radioLabel {
		--color: var(--form-input-color-dark-label);
	}
}

.radioWrapper {
	align-items: center;
	display: flex;
	gap: var(--horizontal-spacing);
	position: relative;

	&:hover:not(:disabled) .radioControl {
		border-color: var(--border-color-hover);
	}
}

.radioInput {
	cursor: pointer;
	height: 0;
	opacity: 0;
	position: absolute;
	width: 0;

	&:focus-visible ~ .radioControl {
		--background-color: var(--form-input-color-background-focus);

		outline: var(--border-width) solid var(--outline-focus);
		outline-offset: 0.125rem;
	}

	&:checked ~ .radioControl {
		--border-color: var(--form-input-color-border-active);

		&::after {
			opacity: 1;
		}
	}

	&:disabled ~ .radioControl {
		--background-color: rgb(from #1d1d1d r g b / 33%);
		--border-color: rgb(from #1d1d1d r g b / 66%);

		cursor: not-allowed;

		&::after {
			background-color: var(--border-color);
		}
	}
}

.radioControl {
	align-items: center;
	background-color: var(--background-color);
	border-color: var(--border-color);
	border-radius: var(--border-radius);
	border-style: var(--border-style);
	border-width: var(--border-width);
	box-sizing: border-box;
	color: var(--color);
	display: flex;
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	height: 1rem;
	justify-content: center;
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	order: 0;
	transition: border-color var(--transition-speed-fast) ease-in-out, outline-offset var(--transition-speed-fast) ease-in-out;
	width: 1rem;

	&::after {
		--background-color: var(--form-input-color-border-active);

		background: var(--background-color);
		border-radius: 50%;
		content: "";
		height: 0.625rem;
		opacity: 0;
		position: absolute;
		transition: opacity var(--transition-speed-fast) ease-in-out;
		width: 0.625rem;
	}
}

.radioLabel {
	--color: var(--form-input-color-label);
	--font-family: var(--form-input-typography-label-family);
	--font-size: var(--form-input-typography-label-size);
	--font-weight: var(--form-input-typography-label-weight);
	--letter-spacing: var(--form-input-typography-label-letter-spacing);
	--line-height: var(--form-input-typography-label-line-height);

	color: var(--color);
	display: block;
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
}
