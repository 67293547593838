.inputContainer {
	--background-color-filled: var(--form-input-color-background-filled);
	--background-color: var(--form-input-color-background-default);
	--border-radius: var(--form-input-border-radius);
	--border-color: var(--form-input-color-border-default);
	--border-style: var(--form-input-border-style);
	--border-color-error: var(--form-input-color-error);
	--border-color-hover: var(--form-input-color-border-hover);
	--border-width: var(--form-input-border-width);
	--color: var(--form-input-color-default);
	--color-placeholder: var(--form-input-color-placeholder);
	--font-family: var(--form-input-typography-default-family);
	--font-size: var(--form-input-typography-default-size);
	--font-weight: var(--form-input-typography-default-weight);
	--horizontal-spacing: var(--spacing-2xs);
	--letter-spacing: var(--form-input-typography-default-letter-spacing);
	--line-height: var(--form-input-typography-default-line-height);
	--outline-focus: var(--form-input-color-border-focus);

	font-family: var(--font-family);
}

.inputField {
	background-color: var(--background-color);
	border-color: var(--border-color);
	border-radius: var(--border-radius);
	border-style: var(--border-style);
	border-width: var(--border-width);
	box-sizing: border-box;
	color: var(--color);
	display: block;
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	height: 3rem;
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	padding-inline: var(--horizontal-spacing);
	transition: border-color var(--transition-speed-fast) ease-in-out, outline-offset var(--transition-speed-fast) ease-in-out;
	width: 100%;

	&:hover:not(.textFieldHasError) {
		border-color: var(--border-color-hover);
	}

	&:focus {
		outline: 1px solid var(--outline-focus);
		outline-offset: 0.25rem;
	}

	&::placeholder {
		color: var(--color-placeholder);
	}
}

.textFieldFilled {
	background-color: var(--background-color-filled);
}

.textFieldHasError {
	border-color: var(--border-color-error);
}

:global(.has-dark-theme) .inputField {
	--background-color: var(--form-input-color-dark-background-default);
	--border-color: var(--form-input-color-dark-border-default);
	--border-color-hover: var(--form-input-color-dark-border-hover);
	--border-color-error: var(--form-input-color-dark-error);
	--color: var(--form-input-color-dark-default);
	--color-placeholder: var(--form-input-color-dark-placeholder);
	--outline-focus: var(--form-input-color-dark-border-focus);
}
