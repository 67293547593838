.carouselTiles {
	--aspect-ratio: 388 / 600;
	--outline-color: var(--color-theme-dark-link-focus-outline-color);
	--outline-style: var(--color-theme-dark-link-focus-outline-style);
	--outline-width: var(--color-theme-dark-link-focus-outline-width);
	--vertical-spacing: var(--spacing-3xl);

	@media (--bp-large) {
		--vertical-spacing: var(--spacing-3xl-desktop);
	}

	max-width: var(--screen-width);
	overflow: hidden;
	position: relative;
}

.carouselTilesContainer {
	margin-inline: auto;
	max-width: calc(var(--max-width-wide) + var(--gutter) * 2);
	padding-inline: var(--gutter);
	position: relative;
}

:global(.wp-block-group.is-layout-constrained) > .carouselTiles,
.carouselTiles {
	margin: var(--vertical-spacing) var(--margin-full-width);
	max-width: var(--screen-width);
}

.carouselTilesTitle {
	--font-family: var(--typography-h-2xl-family);
	--font-size: var(--typography-h-2xl-size);
	--font-weight: var(--typography-h-2xl-weight);
	--letter-spacing: var(--typography-h-2xl-letter-spacing);
	--line-height: var(--typography-h-2xl-line-height);

	margin-bottom: var(--spacing-xl);
	text-align: center;
}

.carouselTilesSlide {
	--slide-spacing: var(--spacing-2xs);
	--slide-size: 80%;
	height: 100%;
	position: relative;
	width: 100%;

	@media (--bp-medium) {
		--slide-spacing: var(--spacing-s-desktop);
		--slide-size: calc(100% / 2);
	}

	@media (--bp-large) {
		--slide-spacing: var(--spacing-s-desktop);
		--slide-size: calc(100% / 3);
	}

	&:hover,
	&:focus-within {

		& .carouselTilesImage {
			transform: scale(1.05);
		}
	}

	& a {
		pointer-events: all;
	}
}

.carouselTilesImageWrapper {
	aspect-ratio: var(--aspect-ratio);
	border-radius: var(--radius-media);
	height: 100%;
	margin: 0;
	object-fit: cover;
	overflow: hidden;
	position: relative;

	&::after {
		background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 75%) 100%);
		bottom: 0;
		content: "";
		display: block;
		height: 66%;
		position: absolute;
		width: 100%;
	}
}

.carouselTilesImage {
	display: block;
	height: 100%;
	object-fit: cover;
	transition: transform var(--transition-speed-normal) ease-out;
	width: 100%;
}

.carouselTilesContent {
	align-items: center;
	color: var(--color-neutral-white);
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: end;
	left: 0;
	margin: 0;
	padding: 1rem;
	position: absolute;
	text-align: center;
	top: 0;
	width: 100%;
	z-index: 1;

	@media (--bp-medium) {
		padding: var(--gutter);
	}
}

.carouselTilesSlideTitle {
	color: var(--color-neutral-white);
	font-size: 1.5rem;
	line-height: 1;
	margin: 0 auto 1.25rem;

	@media (--bp-medium) {
		font-size: 2rem;
	}
}

.carouselTilesSlideLink {
	color: var(--color-neutral-white);
	text-decoration: none;

	&::after {
		content: "";
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&:hover,
	&:focus {
		color: var(--color-neutral-white);
		text-decoration: none;
	}
}

.carouselTilesAuthor {
	color: var(--color-neutral-white);
	font-size: var(--typography-body-s-size);
}

.carouselTilesCta {
	margin-top: 3.125rem;
	text-align: center;
}

.carouselTilesControls {
	margin-top: var(--spacing-m);
	position: static;

	@media (--bp-large) {
		display: none;
	}

	& :global(.carousel__dot) {
		border: 1px solid var(--color-neutral-black);
	}

	& :global(.carousel__dot--active) {
		background-color: var(--color-neutral-black);
	}
}
